import { Client, fetchExchange } from '@urql/core';
import { getCurrentScope } from '@sentry/solidstart';
import { redirect } from '@solidjs/router';
import { isServer } from 'solid-js/web';
import { getConfigValue } from '../modules/config';
import { loggedInUserQuery } from '../queries/user';
import { sentryExchange } from './exchanges/sentry';
import { revalidate, ttlCache } from './cache';
import type { AnyVariables, ClientOptions, DocumentInput, OperationResult } from '@urql/core';

type FetchOptions = { timeout?: number };

export function createFetch({ timeout = 15_000 }: FetchOptions = {}): ClientOptions['fetch'] {
	return async (input, init) => {
		const controller = new AbortController();
		const timer = setTimeout(() => {
			const scope = getCurrentScope();
			scope.setExtras({
				fetchUrl: input,
			});
			controller.abort(new Error('Failed to fetch within a reasonable period'));
		}, timeout);

		const res = await fetch(input, {
			...init,
			signal: controller.signal,
		});
		clearTimeout(timer);
		return res;
	};
}

export function createClient(headers: Record<string, string> = {}, unauthenticatedRedirect: string = '/') {
	const client = new Client({
		url: `${getConfigValue('API_URL') ?? 'http://localhost'}/graphql`,
		exchanges: [sentryExchange, fetchExchange],

		fetch: createFetch(),

		fetchOptions: () => {
			const now = new Date();
			const outHeaders: Record<string, string> = {
				...headers,
				'x-troon-client-platform': `web-${isServer ? 'server' : 'client'}`,
				// Invert this because the backend wants it that way
				'x-tzoffset': `${now.getTimezoneOffset() * -1}`,
				'x-datetime': `${Math.round(now.valueOf() / 1000)}`,
			};

			if (typeof window !== 'undefined') {
				try {
					outHeaders['x-trace-id'] = window.crypto.randomUUID();
					outHeaders['x-session-id'] = window.__SESSIONID__ ?? window.crypto.randomUUID();
				} catch {
					// missing randomUUID is only in unsupported browsers
				}

				const scope = getCurrentScope();
				scope.setExtras(outHeaders);
			}
			return {
				mode: 'cors',
				credentials: 'include',
				referrerPolicy: 'origin',
				headers: outHeaders,
			};
		},
	});

	type QueryArgs = Parameters<(typeof client)['query']>;
	type WrappedQuery = (
		query: QueryArgs[0],
		variables?: QueryArgs[1],
		context?: QueryArgs[2],
	) => ReturnType<(typeof client)['query']>;

	// @ts-expect-error URQL makes this very difficult, but it's correct
	const wrappedQuery: WrappedQuery = async (query, variables = {}, context) => {
		const res = await client.query(query, variables, context);
		await handleUnauthorized(query, res, unauthenticatedRedirect);
		return res;
	};

	type MutationArgs = Parameters<(typeof client)['mutation']>;
	type WrappedMutation = (
		query: MutationArgs[0],
		variables?: MutationArgs[1],
		context?: MutationArgs[2],
	) => ReturnType<(typeof client)['mutation']>;

	// @ts-expect-error URQL makes this very difficult, but it's correct
	const wrappedMutation: WrappedMutation = async (query, variables = {}, context) => {
		const res = await client.mutation(query, variables, context);
		await handleUnauthorized(query, res, unauthenticatedRedirect);
		return res;
	};

	return new Proxy(client, {
		get(target, prop, receiver) {
			if (prop === 'query') {
				return wrappedQuery;
			}

			if (prop === 'mutation') {
				return wrappedMutation;
			}

			return Reflect.get(target, prop, receiver);
		},
	});
}

async function handleUnauthorized(
	query: DocumentInput<unknown, AnyVariables>,
	res: OperationResult<unknown, void | Record<string, unknown>>,
	redirectTo: string = '/',
) {
	if (res.error?.graphQLErrors.some((err) => err.extensions.unauthorized) && query !== loggedInUserQuery) {
		if (!isServer && window.__USER__) {
			window.__USER__ = undefined;
		}
		ttlCache?.delete('loggedInUser');
		await revalidate();
		throw redirect(`/auth/?redirect=${redirectTo}&unauthorized=1`, { revalidate: undefined });
	}
}
